<template>
  <div
    class="group hover:shadow focus-within:shadow flex scroll-mt-[--top-header-height] rounded border bg-white font-normal text-black transition-all"
    :class="{
      '!border-error !bg-error/5': invalid,
      '!bg-disabled cursor-emoji-[🚫]': readonly,
      'focus-within:border-form-focus': !readonly,
      'border-0 !shadow-none': !border,
    }"
    :data-form-field="name"
    :style="{
      '--floatingElYOffset': size === 'small' ? '8px' : '13px',
      '--floatingElYOffsetActive': size === 'small' ? '-8px' : '-11px',
    }"
  >
    <div v-if="prependIcon || $slots.prepend" class="relative w-6 shrink-0">
      <slot name="prepend">
        <app-icon
          v-if="prependIcon"
          class="mt-px"
          :class="[
            floatingElementsClasses,
            { 'group-focus-within:text-primary': !readonly },
          ]"
          :icon="prependIcon"
          :size="18"
        />
      </slot>
    </div>

    <component :is="labelElementAs ?? 'label'" class="relative flex-auto">
      <span
        class="text-sm font-semibold"
        :class="[floatingElementsClasses, { 'sr-only': hideLabel }]"
        >{{ label }}
        <span
          v-if="nonRequiredMention"
          class="font-normal opacity-50 text-xs hidden sm:inline"
          >({{ $t("app.form.labels.optional_field") }})</span
        >
      </span>

      <slot
        v-bind="{
          inputClass: [
            'text-left outline-none text-black relative !bg-transparent placeholder:text-gray-300 placeholder:italic h-full pt-4 w-full  md:text-sm',
            {
              'px-2': border,
              'cursor-emoji-[🚫]': readonly,
              '!py-2 !text-base': hideLabel,
              '!text-sm': size === 'small',
              'min-h-[48px]': size !== 'small',
              'min-h-[38px]': size === 'small',
            },
          ],
        }"
      />
    </component>

    <div
      v-if="$slots.append"
      class="flex items-center justify-center py-2 pr-2"
    >
      <slot name="append" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  label: string;
  name: string;
  labelElementAs?: "div";
  prependIcon?: string;
  invalid?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  readonly?: boolean;
  nonRequiredMention?: boolean;
  hideLabel?: boolean;
  size?: "small";
  border?: boolean;
}>();

const hasValue = computed(() => {
  if (typeof properties.value === "number") return true;
  if (typeof properties.value === "string") return !!properties.value;
  if (Array.isArray(properties.value)) return properties.value.length > 0;

  return false;
});

const floatingElementsClasses = computed(() => {
  return [
    "origin-top-left absolute left-2 top-[--floatingElYOffset] transition-all",
    {
      "scale-90 translate-y-[--floatingElYOffsetActive]":
        !properties.hideLabel && (hasValue.value || properties.invalid),
      "text-error": properties.invalid,
      "group-focus-within:translate-y-[--floatingElYOffsetActive] group-focus-within:scale-90":
        !properties.hideLabel && !properties.readonly,
    },
  ];
});
</script>
